import { ipUrl } from '@/utils/config'

const map = {
  // [`//${ipUrl}:7200/`]: `//${ipUrl}:8089/demo-vue2/`,
  [`//${ipUrl}:7301/`]: `//${ipUrl}/child_project02/`,
  // [`//${ipUrl}:7400/`]: `//${ipUrl}:8089/child_demo/`,
  [`//${ipUrl}:8089/`]: `//${ipUrl}/main-vue/`,
  // [`//${ipUrl}:9100/`]: `//${ipUrl}:8089/mms-cm/`,
  [`//${ipUrl}:3001/`]: `//${ipUrl}/mmscmWeb/`,
  [`//${ipUrl}:5001/`]: `//${ipUrl}/mmscmBms/`,
};

export default function hostMap(host) {
  if (process.env.NODE_ENV === "production") return map[host];
  return host;
}
