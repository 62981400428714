// import { login, logout, getInfo } from '@/api/login'
import { setToken, setRefreshToken, removeToken, removeRefreshToken, getToken } from '@/utils/auth'
import {setStore, getStore} from '@/utils/store'
import {loginByUsername, loginBySocial, getUserInfo, logout, refreshToken, getButtons, dictSelect} from '@/api/login'
import md5 from 'js-md5'
import wujieVue from "wujie-vue2";
import { getOptions } from "@/api/public.js";

const user = {
    state: {
        token: getToken() || '',
        name: '',
        avatar: '',
        roles: [],
        permissions: getStore({name: 'permissions'}) || {},
        userInfo: getStore({name: 'userInfo'}) || [],
        logo: '',
        tenantId: getStore({name: 'tenantId'}) || '',
        dictCodeList: getStore({name: 'dictCodeList'}) || {},
        refreshToken: getStore({name: 'refreshToken'}) || '',
        dictList: getStore({name: 'dictList'}) || {},
    },

    mutations: {
        SET_DICT_LIST: (state, data) => {
          state.dictList = data
          setStore({name: 'dictList', content: state.dictList})
        },
        SET_REFRESH_TOKEN: (state, refreshToken) => {
          setRefreshToken(refreshToken)
          state.refreshToken = refreshToken;
          setStore({name: 'refreshToken', content: state.refreshToken})
        },
        SET_DICT_CODE: (state, dictCodeList) => {
          state.dictCodeList = dictCodeList;
          setStore({name: 'dictCodeList', content: state.dictCodeList})
        },
        DEL_DICT_CODE: (state) => {
          state.dictCodeList = {}
          setStore({name: 'dictCodeList', content: {}})
        },
        SET_TOKEN: (state, token) => {
            setToken(token)
            state.token = token
            setStore({name: 'token', content: state.token})
        },
        SET_NAME: (state, name) => {
            state.name = name
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        },
        SET_PERMISSIONS: (state, permissions) => {
            if(permissions.length === 0){
              setStore({name: 'permissions', content: []})
              return;
            }
            let result = [];

            function getCode(list) {
              list.forEach(ele => {
                if (typeof (ele) === 'object') {
                  const chiildren = ele.children;
                  const code = ele.code;
                  if (chiildren) {
                    getCode(chiildren)
                  } else {
                    result.push(code);
                  }
                }
              })
            }
      
            getCode(permissions);
            state.permissions = {};
            result.forEach(ele => {
              state.permissions[ele] = true;
            });
            setStore({name: 'permissions', content: state.permissions})
        },
        SET_USERINFO: (state, userInfo) => {
            state.userInfo = userInfo;
            setStore({name: 'userInfo', content: state.userInfo})
        },
        SET_LOGO: (state, logo) => {
            state.logo = logo;
        },
        SET_TENANT_ID: (state, tenantId) => {
          state.tenantId = tenantId;
          setStore({name: 'tenantId', content: state.tenantId})
        },
    },

    actions: {
        //获取系统按钮
        GetButtons({commit}) {
          return new Promise((resolve) => {
            getButtons().then(res => {
              const data = res.data;
              commit('SET_PERMISSIONS', data);
              resolve();
            })
          })
        },

        //根据用户名登录
        LoginByUsername({dispatch, commit}, userInfo) {
          return new Promise((resolve, reject) => {
            loginByUsername(userInfo.tenantId, userInfo.deptId, userInfo.username, md5(userInfo.password), userInfo.type, userInfo.key, userInfo.code).then(res => {
              const data = res;
              if (data.error_description) {
                Message({
                  message: data.error_description,
                  type: 'error'
                })
              } else {
                commit('SET_TOKEN', data.access_token);
                commit('SET_REFRESH_TOKEN', data.refresh_token);
                commit('SET_TENANT_ID', data.tenant_id);
                commit('SET_USERINFO', data);
                dispatch('getDictCode')
                dispatch('GetButtons');
                dispatch('GetDictList');
              }
              
              resolve();
            }).catch(error => {
              reject(error);
            })
          })
        },

        GetDictList({commit}) {
          return new Promise((resolve, reject) => {
            dictSelect().then(res => {
              commit('SET_DICT_LIST', res.data);
              resolve();
            }).catch(error => {
              reject(error)
            })
          })
        },

        getDictCode({state, commit}) {
          const params = {
            customerId: 1
          }
          getOptions('/mmscm-system/tms-order-state/query/order-state-by-customer-id',params).then(res => {
            const {code, data} = res
            let orderStateList = []
            let waybillStateList = []
            let waybillStateIdList = []
            data.map(item => {
              if (item.orderState || item.orderState === 0) {
                orderStateList.push({
                  label: item.orderStateName,
                  code: item.orderState
                })
              }
              if ((item.waybillState || item.waybillState === 0) && waybillStateIdList.indexOf(item.waybillState) < 0) {
                waybillStateIdList.push(item.waybillState)
                waybillStateList.push({
                  label: item.waybillStateName,
                  code: item.waybillState
                })
              }
            })
            waybillStateList.push({
              label: '电子回单',
              code: 300
            })
            waybillStateList.push({
              label: '纸质回单',
              code: 400
            })
            let dictCodeList = {
              orderStateList: orderStateList,
              waybillStateList: waybillStateList
            }
            commit('SET_DICT_CODE', dictCodeList);
          })
        },

        // 登录
        Login({ commit }, userInfo) {
            const userId = userInfo.userId.trim()
            const password = userInfo.password
            const code = userInfo.code
            const uuid = userInfo.uuid
            return new Promise((resolve, reject) => {
                login(userId, password, code, uuid).then(res => {
                    setToken(res.data.access_token)
                    commit('SET_TOKEN', res.data.access_token)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 获取用户信息
        GetInfo({ commit, state }) {
            return new Promise((resolve, reject) => {
              getUserInfo().then(res => {
                    const user = res.user
                    const avatar = (user.avatar == "" || user.avatar == null) ? require("@/assets/logo/favicon.png") : user.avatar;
                    if (res.permissions && res.permissions.length > 0) {
                        commit('SET_PERMISSIONS', res.permissions)
                    }
                    if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
                        commit('SET_ROLES', res.roles)
                    } else {
                        commit('SET_ROLES', ['ROLE_DEFAULT'])
                    }
                    commit('SET_NAME', user.name)
                    commit('SET_USERINFO', user)
                    commit('SET_AVATAR', avatar)
                    commit('SET_LOGO', res.logo)
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 退出系统
        LogOut({ commit, state }) {
            return new Promise((resolve, reject) => {
              console.log('logout',state.token)
                logout(state.token).then(() => {
                    commit('SET_TOKEN', '')
                    commit('SET_ROLES', [])
                    commit('SET_PERMISSIONS', [])
                    commit('SET_USERINFO', [])
                    removeToken()
                    removeRefreshToken();
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 前端 登出
        FedLogOut({ commit }) {
            return new Promise(resolve => {
                commit('SET_TOKEN', '')
                removeToken()
                resolve()
            })
        },

        //刷新token
        refreshToken({state, commit}, userInfo) {
          window.console.log('handle refresh token');
          return new Promise((resolve, reject) => {
            const deptId = !validatenull(userInfo) ? userInfo.deptId : state.userInfo.dept_id
            refreshToken(state.refreshToken, state.tenantId, deptId).then(res => {
              const data = res.data;
              commit('SET_TOKEN', data.access_token);
              commit('SET_REFRESH_TOKEN', data.refresh_token);
              commit('SET_USERINFO', data);
              resolve();
            }).catch(error => {
              reject(error)
            })
          })
        },
    }
}

export default user
