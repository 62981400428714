<template>
  <div class="user-inform">
    <el-dialog title="提示" :visible.sync="dialogVisible" width="1160px" :before-close="handleClose">
      <span style="padding-bottom: 40px;display: inline-block;">
        <commonTable :tableMap="tableMap" :tableData="tableData" :tableConfig="tableConfig"
          @handleCurrentChange="handleCurrentChange" @pagination="getList" @handleView="handleView">
          <template v-slot:btn="{ rowData, template, scope }">
            <template v-for="(item, key) in template">
              <el-tag v-if="key === 'isRead'">{{rowData.isRead === 1 ? '已读' : '未读'}}</el-tag>
              <el-tag v-if="key === 'isHint'">{{rowData.isHint === 1 ? '提示' : '不提示'}}</el-tag>
            </template>
          </template>
        </commonTable>
      </span>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span> -->
    </el-dialog>
    <InformView ref="informView" />
  </div>
</template>

<script>
import { noticeUserLog } from '@/api/menu.js';
import {add} from '@/api/system/noticeUserLog.js'
import commonTable from '@/components/Common/table';
import InformView from './informView';
export default {
  components: { commonTable, InformView },
  data() {
    return {
      dialogVisible: false,
      // 查询参数
      queryParams: {
        current: 1,
        size: 10,
      },
      tableData: {
        data: [],
        total: 0,
        page: 1,
        pageSize: 10,
      },

      tableMap: [
        {
          name: '版本号',
          prop: 'versions',
          width: 180,
        },
        { prop: 'messageTitle', name: '标题', width: 300 },
        {
          prop: 'isRead',
          name: '是否已读',
          width: 120,
          template: {
            type: 'custom',
            isRead: {},
          },
        },
        {
          prop: 'isHint',
          name: '是否提示',
          width: 120,
          template: {
            type: 'custom',
            isHint: {},
          },
        },
        { prop: 'createTime', name: '创建时间', width: 220 },
        {
          name: '操作',
          width: 100,
          template: {
            type: 'btn',
            btn: [
              {
                label: '查看',
                event: 'handleView',
                type: 'text',
              },
            ],
          },
        },
      ],
      tableConfig: {
        // 其他配置, 选填
        hidePage: false, // 隐藏分页, 默认false
        hideIndex: false, // 隐藏序号列, 默认false
        loading: true, // 遮罩层
        rowKey: 'id',
      },
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },

    getList() {
      this.tableConfig.loading = true;
      this.queryParams.current = this.tableData.page;
      this.queryParams.size = this.tableData.pageSize;
      noticeUserLog(this.queryParams).then((res) => {
        if (res.code === 200) {
          this.tableConfig.loading = false;
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
        }
      });
    },
    
    /** 分页 */
    handleCurrentChange(val) {
      this.tableData.page = val;
      this.getList();
    },

    handleView(row) {
      const params = {
        noticeId: row.noticeId,
        isRead: 1,
        isHint: 0,
      };
      add(params).then((res) => {
        if (res.code === 200) {
          this.$refs.informView.dialogVisible = true
          this.$refs.informView.getInfo(row.noticeId);
          this.getList();
        }
      });
    }
  },
};
</script>

<style>
</style>