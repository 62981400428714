import request from '@/utils/request'

// 获取路由
export const getRouters = () => {
  return request({
    url: '/mmscm-system/menu/mainMenu',
    method: 'get'
  })
}

// 获取用户日志
export const noticeUserLog = (params) => {
  return request({
      url: '/mmscm-system/notice-user-log/user/page',
      method: 'get',
      params: {
          ...params
      }
  })
}

// 获取通知消息详情
export const noticeMessageDetail = (id) => {
  return request({
      url: '/mmscm-system/notice-message/detail',
      method: 'get',
      params: {
          id
      }
  })
}