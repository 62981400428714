import request from '@/utils/request'
import website from "@/config/website";

export const loginByUsername = (tenantId, deptId, username, password, type, key, code) => request({
  url: '/auth/oauth/token',
  method: 'post',
  headers: {
    'Tenant-Id': tenantId,
    'Dept-Id': (website.switchMode ? deptId : ''),
    'Captcha-Key': key,
    'Captcha-Code': code,
  },
  params: {
    tenantId,
    username,
    password,
    grant_type: (website.captchaMode ? "captcha" : "password"),
    scope: "all",
    type
  }
});

export const loginBySocial = (tenantId, source, code, state) => request({
  url: '/auth/oauth/token',
  method: 'post',
  headers: {
    'Tenant-Id': tenantId
  },
  params: {
    tenantId,
    source,
    code,
    state,
    grant_type: "social",
    scope: "all",
  }
})

export const refreshToken = (refresh_token, tenantId, deptId,) => request({
  url: '/auth/oauth/token',
  method: 'post',
  headers: {
    'Tenant-Id': tenantId,
    'Dept-Id': (website.switchMode ? deptId : '')
  },
  params: {
    tenantId,
    refresh_token,
    grant_type: "refresh_token",
    scope: "all",
  }
});

export const registerGuest = (form, oauthId) => request({
  url: '/mmscm-user/register-guest',
  method: 'post',
  params: {
    tenantId: form.tenantId,
    name: form.name,
    account: form.account,
    password: form.password,
    oauthId
  }
});

export const getButtons = () => request({
  url: '/mmscm-system/menu/buttons',
  method: 'get'
});

export const getCaptcha = () => request({
  url: '/auth/oauth/captcha',
  method: 'get'
});

export const logout = () => request({
  url: '/auth/oauth/logout',
  method: 'get'
});

export const getUserInfo = () => request({
  url: '/auth/oauth/user-info',
  method: 'get'
});

export const sendLogs = (list) => request({
  url: '/auth/oauth/logout',
  method: 'post',
  data: list
});

export const clearCache = () => request({
  url: '/auth/oauth/clear-cache',
  method: 'get'
});

export const dictSelect = () => request({
  url: '/mmscm-system/dict/select',
  method: 'get',
});
// ========================================================

// 登录方法
// export function login(userId, password, code, uuid) {
//   const data = {
//     userId,
//     password,
//     code,
//     uuid
//   }
//   return request({
//     url: '/auth/login',
//     headers: {
//       isToken: false
//     },
//     method: 'post',
//     data: data
//   })
// }


// // 注册方法
// export function register(data) {
//   return request({
//     url: '/register',
//     headers: {
//       isToken: false
//     },
//     method: 'post',
//     data: data
//   })
// }

// // 获取用户详细信息
// export function getInfo() {
//   return request({
//     url: '/system/user/getInfo',
//     method: 'get'
//   })
// }

// // 退出方法
// export function logout() {
//   return request({
//     url: '/auth/logout',
//     method: 'Delete'
//   })
// }

// // 获取验证码
// export function getCodeImg() {
//   return request({
//     url: '/captchaImage',
//     headers: {
//       isToken: false
//     },
//     method: 'get',
//     timeout: 20000
//   })
// }

// // 重置密码
// export function resetPwd() {
//   return request({
//     url: '/system/user/resetPwd',
//     method: 'post',
//     data: data
//   })
// }