import request from '@/utils/request'

// 组件请求
export function getComponents(type) {
    return request({
        url: '/mmscm-system/module/getByIdList/' + type,
        method: 'post',
        loading: false,
    })
}


export const getOptions = (url, params) => {
    return request({
      url: url,
      method: 'get',
      params: {
        ...params
      }
    })
}

export const upload = (url, headers, params) => {
    return request({
      url: url,
      method: 'get',
      headers: headers,
      params: {
        ...params
      }
    })
}