<template>
    <div class="rz-table">
      <el-table ref="rzTable" v-loading="tableConfig.loading" class="rz-table-content" :data="tableData.data" 
        :border="tableConfig.border" style="width: 100%;text-align:left" :size="tableConfig.size || 'small'"
        :header-cell-style="headerStyle" @selection-change="handleSelectionChange"
        @row-click="rowClick" @expand-change="expandChange" :row-key="getRowKey">
        <el-table-column v-if="tableConfig.showCheckBox" type="selection" align="center" :key="Math.random()" />
        <el-table-column width="60" v-if="!tableConfig.hideIndex" type="index" align="center" label="序号" :fixed="tableConfig.indexFixed"
          :key="Math.random()" />
        <template v-for="(item,index) in tableMap">
          <el-table-column v-if="item.type == 'selection'" width="60" type="selection" align="center" :key="index" />
          <el-table-column v-if="item.type == 'expand'" type="expand" align="center" 
          :key="index">
            <template slot-scope="scope">
              <slot name="content" :rowData="scope.row" :scope="scope"></slot>
            </template>
          </el-table-column>
          <el-table-column v-else :prop="item.prop" :type="item.type" :align="item.align || 'center'" :label="item.name"
            :width="item.width" :key="index" :fixed="item.fixed">
            <template slot-scope="scope">
              <!-- 显示按钮 -->
              <div v-if="item.template && item.template.type === 'btn'">
                <!-- <slot name="btn" :rowData="scope.row" :template="item.template" :scope="scope"> -->
                  <!-- {{ item.template.btn }} -->
                  <el-button v-hasPermi="btn.hasPermi" class="rz-table-btn" v-for="btn in item.template.btn" :key="btn.label"
                    :type="btn.type || 'primary'" 
                    :style="btn.style" 
                    :size="btn.size || 'mini'" 
                    @click="emitEvent(btn.event, scope.row)" 
                    :disabled="disabledBtn(btn,scope.row)"
                  >
                    <i :class="btn.icon"></i>{{ btn.label }}
                  </el-button>
                <!-- </slot> -->
              </div>
              <!-- 自定义内容 -->
              <div v-else-if="item.template && item.template.type === 'custom'">
                <slot name="btn" :rowData="scope.row" :template="item.template" :scope="scope"></slot>
              </div>
              <!-- 常规文本 -->
              <div v-else>
                <el-popover placement="top-start"
                  :width="item.width"
                  :template="item.template"
                  trigger="hover"
                  :content="scope.row[item.prop]+''">
                    <div :title="scope.row[item.title]" slot="reference" class="twoLineCls">{{scope.row[item.prop]}}</div>
                </el-popover>
                <!-- <span :title="scope.row[item.title]">{{ scope.row[item.prop] }}</span> -->
              </div>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <!-- 分页 -->
      <page-count v-if="!tableConfig.hidePage && tableData.total" class="pageCount"
        @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" :tableData="tableData" @pagination="pagination"/>
    </div>
  </template>
  
  <script>
    import PageCount from "./pageCount.vue";
    export default {
      components: {
        PageCount
      },
      data() {
        return {
          selectDataForPage: {}
        }
      },
      props: {
        tableMap: {
          type: Array,
          default: () => []
        },
        tableData: {
          type: Object,
          default: () => {}
        },
        tableConfig: {
          type: Object,
          required: false,
          default: () => {
            return {
              hidePage: false, // 隐藏分页
              hideIndex: false, // 隐藏序号列
              showCheckBox: false, // 第一列显示复选框
              loading: false, // 遮罩层
              border: true, // 隐藏表格线
              size: '', // 表格尺寸
            };
          }
        },
      },
      methods: {
        getRowKey(row){
          return row.id;
        },
        emitEvent(event, data) {
          this.$emit(event, data);
        },
        //修改table中表头颜色
        headerStyle() {
          return "background:rgba(140,140,140,0.1);color: #000000FF;text-align: center";
        },
        //修改table表头的高度
        headerRowStyle() {
          return "height:32px";
        },
  
        // 设置每页多少条
        handleSizeChange(val){
          this.$emit("handleSizeChange", val);
        },
  
        //分页
        handleCurrentChange(val) {
          this.$emit("handleCurrentChange", val);
        },
  
        expandChange(row){
          this.$emit("expandChange", row);
        },
  
        // 当某一行被点击时会触发该事件
        rowClick(row, column, event){
          this.$refs.rzTable.toggleRowExpansion(row);
          this.$emit("rowClick", row);
        },
  
        pagination(){
          this.$emit("pagination");
        },
  
        // 复选框
        handleSelectionChange(data) {
          this.selectDataForPage[this.tableData.page] = data;
          this.$emit("selection-change", data, this.selectDataForPage);
        },
        // 隐藏按钮
        disabledBtn(btn, data) {
          if (typeof btn.disabled == 'function') {
            return btn.disabled(data);
          } else {
            return false;
          }
        }
      },
      updated() {
        this.$refs.rzTable.doLayout();
      }
    };
  </script>
  
  <style scoped>
    .rz-table {
      width: 100%;
      height: 100%;
    }
  
    .rz-table-content>>>.el-table__row td:last-child .cell {
      padding: 0 !important;
    }
  
    .el-table .cell {
      height: 32px !important;
    }
  
    .rz-table-btn {
      border: none;
    }
  
    .rz-table-btn[disabled] {
      opacity: .4;
    }
    .pageCount{
      overflow: auto;
    }
    .twoLineCls{
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  </style>