<template>
  <div class="NoticeClass">
    <el-tabs type="border-card" @tab-click="handleClick" v-model="activeName">
      <el-tab-pane label="通知公告" name="1">
        <div class="conList">
          <el-scrollbar style="height:100%" wrap-style="overflow-x:hidden;">
            <div v-if="list.length === 0" class="noData">
              暂无数据
            </div>
            <div class="list" v-for="(item, index) in list" :key="index" @click="view(item)">
              <div class="title" :class="item.isRead === 1 ? 'isRead' : ''">
                <img v-if="item.isRead === 1" src="@/assets/images/bg/read.png" alt="" class="readImg">
                <img v-else src="@/assets/images/bg/noRead.png" alt="" class="readImg">
                {{ item.messageTitle }}
              </div>
              <div class="time" :class="item.isRead === 1 ? 'isRead' : ''">
                {{ item.createTime }}
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="pag">
          <el-pagination background layout="prev, pager, next" :total="page.total"
            :page="page.current" :limit="page.size" @current-change="handleCurrentChange" />
        </div>
      </el-tab-pane>
      <el-tab-pane label="系统公告" name="2">
        <div class="conList">
          <el-scrollbar style="height:100%" wrap-style="overflow-x:hidden;">
            <div v-if="list.length === 0" class="noData">
              暂无数据
            </div>
            <div class="list" v-for="(item, index) in list" :key="index" @click="view(item)">
              <div class="title" :class="item.isRead === 1 ? 'isRead' : ''">
                <img v-if="item.isRead === 1" src="@/assets/images/bg/read.png" alt="" class="readImg">
                <img v-else src="@/assets/images/bg/noRead.png" alt="" class="readImg">
                {{ item.messageTitle }}
              </div>
              <div class="time" :class="item.isRead === 1 ? 'isRead' : ''">
                {{ item.createTime }}
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="pag">
          <el-pagination background layout="prev, pager, next" :total="page.total"
            :page="page.current" :limit="page.size" @current-change="handleCurrentChange" />
        </div>
      </el-tab-pane>
      <el-tab-pane label="APP公告" name="3">
        <div class="conList">
          <el-scrollbar style="height:100%" wrap-style="overflow-x:hidden;">
            <div v-if="list.length === 0" class="noData">
              暂无数据
            </div>
            <div class="list" v-for="(item, index) in list" :key="index" @click="view(item)">
              <div class="title" :class="item.isRead === 1 ? 'isRead' : ''">
                <img v-if="item.isRead === 1" src="@/assets/images/bg/read.png" alt="" class="readImg">
                <img v-else src="/img/bg/noRead.png" alt="" class="readImg">
                {{ item.messageTitle }}
              </div>
              <div class="time" :class="item.isRead === 1 ? 'isRead' : ''">
                {{ item.createTime }}
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="pag">
          <el-pagination background layout="prev, pager, next" :total="page.total"
            :page="page.current" :limit="page.size" @current-change="handleCurrentChange" />
        </div>
      </el-tab-pane>
      <el-tab-pane label="新闻资讯" name="4">
        <div class="conList">
          <el-scrollbar style="height:100%" wrap-style="overflow-x:hidden;">
            <div v-if="list.length === 0" class="noData">
              暂无数据
            </div>
            <div class="list" v-for="(item, index) in list" :key="index" @click="view(item)">
              <div class="title" :class="item.isRead === 1 ? 'isRead' : ''">
                <img v-if="item.isRead === 1" src="@/assets/images/bg/read.png" alt="" class="readImg">
                <img v-else src="@/assets/images/bg/noRead.png" alt="" class="readImg">
                {{ item.messageTitle }}
              </div>
              <div class="time" :class="item.isRead === 1 ? 'isRead' : ''">
                {{ item.createTime }}
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="pag">
          <el-pagination background layout="prev, pager, next" :total="page.total"
            :page="page.current" :limit="page.size" @current-change="handleCurrentChange" />
        </div>
      </el-tab-pane>
      <el-tab-pane label="操作手册" name="5">
        <div class="conList">
          <el-scrollbar style="height:100%" wrap-style="overflow-x:hidden;">
            <div v-if="list.length === 0" class="noData">
              暂无数据
            </div>
            <div class="list" v-for="(item, index) in list" :key="index" @click="view(item)">
              <div class="title" :class="item.isRead === 1 ? 'isRead' : ''">
                <img v-if="item.isRead === 1" src="@/assets/images/bg/read.png" alt="" class="readImg">
                <img v-else src="@/assets/images/bg/noRead.png" alt="" class="readImg">
                {{ item.messageTitle }}
              </div>
              <div class="time" :class="item.isRead === 1 ? 'isRead' : ''">
                {{ item.createTime }}
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="pag">
          <el-pagination background layout="prev, pager, next" :total="page.total"
            :page="page.current" :limit="page.size" @current-change="handleCurrentChange" />
        </div>
      </el-tab-pane>
    </el-tabs>
    <InformViewVue ref="informViewVue" @restTable="search('1')" />
  </div>
</template>

<script>
import {getList, add} from '@/api/system/noticeUserLog.js'
import InformViewVue from '@/layout/components/userInform/informView.vue';

export default {
  name: 'Notice',
  components: {
    InformViewVue,
  },
  data() {
    return {
      activeName: '1',
      list: [],
      page: {
        total: 0,
        current: 1,
        pages: 0,
        size: 10,
      },
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.page.current = val;
      this.search('1');
    },
    handleClick(tab, event) {
      this.list = [];
      this.page = {
        current: 1,
        pages: 0,
        size: 10,
        total: 0,
      };
      this.search(this.activeName);
    },
    view(row) {
      const params = {
        noticeId: row.noticeId,
        isRead: 1,
        isHint: 0,
      };
      add(params).then((res) => {
        if (res.code === 200) {
          this.$refs.informViewVue.dialogVisible = true
          this.$refs.informViewVue.getInfo(row.noticeId)
          this.search('1');
        }
      });
    },
    search(type) {
      let params = {
        type: type,
        size: this.page.size,
        current: this.page.current,
      };
      getList(params).then((res) => {
        if (res.code === 200) {
          this.list = res.data.records;
          this.page.total = res.data.total;
          this.page.current = res.data.current;
          this.page.size = res.data.size;
          this.page.pages = res.data.pages;
        }
      });
    }
  },
  mounted() {
    this.search('1');
  },
};
</script>

<style lang="scss" scoped>
.conList {
  height: 370px;
  overflow: auto;
}
.list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: left;
  box-sizing: border-box;
  padding: 0 20px;
  border-radius: 8px;
  height: 36px;
  margin-bottom: 10px;
  cursor: pointer;
}
.list:hover {
  .title {
    color: #409EFF;
    height: 38px;
  }
  .time {
    color: #409EFF;
    height: 38px;
  }
}
.title {
  font-size: 15px;
  color: #333;
  line-height: 36px;
  position: relative;
  text-align: left;
  height: 38px;
  width: 70%;
  overflow: hidden;//溢出隐藏
  white-space: nowrap;//禁止换行
  text-overflow: ellipsis;//...
  padding-left: 26px;
}

.time {
  font-size: 14px;
  color: #777;
  height: 38px;
}
.pag {
  display: flex;
  justify-content: end;
}
.isRead {
  color: #999;
}
.readImg {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  position: absolute;
  left:0;
  top: 0;
  bottom: 0;
  margin: auto;
}
:deep(.el-tabs__item) {
  font-size: 16px;
}
.noData {
  width: 100%;
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: center;
}
</style>
