import request from '@/utils/request'

export const getList = (params) => {
    return request({
        url: '/mmscm-system/notice-user-log/user/page',
        method: 'get',
        params: {
            ...params
        }
    })
}

export const getDetail = (id) => {
    return request({
        url: '/mmscm-system/notice-message/detail',
        method: 'get',
        params: {
            id
        }
    })
}

export const add = (data) => {
    return request({
        url: '/mmscm-system/notice-user-log/save',
        method: 'post',
        data
    })
}
export const update = (data) => {
    return request({
        url: '/mmscm-system/notice-user-log/update',
        method: 'post',
        data
    })
}
