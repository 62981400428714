import Vue from 'vue'
import directive from '../../directive' // directive
import auth from '@/plugins/auth'
import router, { constantRoutes, dynamicRoutes } from '@/router'
import { getRouters } from '@/api/menu'
import Layout from '@/layout/index'
import ParentView from '@/components/ParentView'
import InnerLink from '@/layout/components/InnerLink'
import {setStore} from '@/utils/store'

const permission = {
    state: {
        routes: [],
        addRoutes: [],
        defaultRoutes: [],
        topbarRouters: [],
        sidebarRouters: []
    },
    mutations: {
        SET_ROUTES: (state, routes) => {
            state.addRoutes = routes;
            console.log(constantRoutes);
            state.routes = constantRoutes.concat(routes)
        },
        SET_DEFAULT_ROUTES: (state, routes) => {
            state.defaultRoutes = constantRoutes.concat(routes)
        },
        SET_TOPBAR_ROUTES: (state, routes) => {
            state.topbarRouters = routes
        },
        SET_SIDEBAR_ROUTERS: (state, routes) => {
            state.sidebarRouters = routes
            setStore({name: 'sidebarRouters', content: routes})
        },
    },
    actions: {
        // 生成路由
        GenerateRoutes({ commit }) {
            return new Promise(resolve => {
                // 向后端请求路由数据
                getRouters().then(res => {
                  //服务没连上，为啥左侧菜单出来了？
                    
                    // const sdata = JSON.parse(JSON.stringify(routerData))
                    // const rdata = JSON.parse(JSON.stringify(routerData))
                    const sdata = JSON.parse(JSON.stringify(res.data));
                    const rdata = JSON.parse(JSON.stringify(res.data));
                    const sidebarRoutes = filterAsyncRouter(sdata)
                    const rewriteRoutes = filterAsyncRouter(rdata, false, true)
                    console.log("dynamicRoutes",dynamicRoutes)
                    // debugger
                    const asyncRoutes = filterDynamicRoutes(dynamicRoutes);
                    rewriteRoutes.push({ path: '*', redirect: '/404', hidden: true })
                    router.addRoutes(asyncRoutes);
                    router.addRoutes(rewriteRoutes);
                    console.log(router);
                    commit('SET_ROUTES', rewriteRoutes)
                    commit('SET_SIDEBAR_ROUTERS', constantRoutes.concat(sidebarRoutes))
                    commit('SET_DEFAULT_ROUTES', sidebarRoutes)
                    commit('SET_TOPBAR_ROUTES', sidebarRoutes)
                    console.log("rewriteRoutes",rewriteRoutes)
                    Vue.use(directive)
                    resolve(rewriteRoutes)
                })
            })
        }
    }
}

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false) {
    return asyncRouterMap.filter(route => {
        if (type && route.children) {
            route.children = filterChildren(route.children)
        }
        // if(route.params){
        //   route.params = {path: route.params}
        // }
        if (route.component && route.component != '') {
            // Layout ParentView 组件特殊处理
            if (route.component === 'Layout') {
                route.component = Layout
            } else if (route.component === 'ParentView') {
                route.component = ParentView
            } else if (route.component === 'InnerLink') {
                route.component = InnerLink
            } else {
                route.component = loadView(route.component)
            }
        }else{
          route.component = loadView(route.component);
        }
        if (route.children != null && route.children && route.children.length) {
            route.children = filterAsyncRouter(route.children, route, type)
        } else {
            delete route['children']
            delete route['redirect']
        }
        return true
    })
}

function filterChildren(childrenMap, lastRouter = false) {
    var children = []
    childrenMap.forEach((el, index) => {
        if (el.children && el.children.length) {
            if (el.component === 'ParentView' && !lastRouter) {
                el.children.forEach(c => {
                    // c.path =  c.path
                    // c.path = el.path + '/' + c.path
                    if (c.children && c.children.length) {
                        children = children.concat(filterChildren(c.children, c))
                        return
                    }
                    children.push(c)
                })
                return
            }
        }
        if (lastRouter) {
            // el.path = el.path
            // el.path = lastRouter.path + '/' + el.path
        }
        children = children.concat(el)
    })
      // console.log("routerPath666:::",children);
    return children
}

// 动态路由遍历，验证是否具备权限
export function filterDynamicRoutes(routes) {
    const res = []
    routes.forEach(route => {
        if (route.permissions) {
            if (auth.hasPermiOr(route.permissions)) {
                res.push(route)
            }
        } else if (route.roles) {
            if (auth.hasRoleOr(route.roles)) {
                res.push(route)
            }
        }
    })
    return res
}

export const loadView = (view) => {
    if (process.env.NODE_ENV === 'development') {
      if(view){
        // console.log(`页面对应组件地址：@/views/${view}`);
        return (resolve) => require([`@/views/${view}`], resolve)
      }
    } else {
        // 使用 import 实现生产环境的路由懒加载
        if(view){
          return () => import(`@/views/${view}`)

        }
    }
}

export default permission
