<template>
    <div class="home">
        <!-- <div><img :src="logo" style="width: 300px;height: 250px;margin: 70px auto;" alt="" srcset="">
        </div> -->
        <!-- <div><img src="../assets/images/01.jpg" alt="" ></div> -->
        <!-- <div class="text" style="margin-top: 18%">欢迎使用旭日东升供应链管理系统</div> -->
        <el-tabs v-model="activeName" type="card" @click="handleClick">
          <el-tab-pane label="首页" name="first">
            <div class="text" style="margin-top: 18%">欢迎使用旭日东升供应链管理系统</div>
          </el-tab-pane>
          <el-tab-pane label="公告" name="second">
            <div class="flex" style="padding-bottom: 4px;padding-top: 4px;">
              <div class="card_group">
                <Notice ref="noticeRef"/>
              </div>
              <div class="card_group"></div>
            </div>
          </el-tab-pane>
        </el-tabs>

    </div>
</template>

<script>
import Notice from "./Notice.vue";

export default {
    name: "Home",
    components: {
        Notice
    },
    data() {
        return {
            // logo: logoImg
            activeName: 'second'
        };
    },
    methods: {
      handleClick(){
        if(this.activeName === 'second'){
            this.$refs.noticeRef.search('1');
        }
      }
    },
    mounted() {
        if(this.$store.state.user.logo){
            this.logo = this.$store.state.user.logo;
        }
    },
};
</script>

<style scoped lang="scss">
.home {
    padding-top: 7px;
    padding-left: 0px;
   
    height: calc(100vh - 84px);
    .text {
        font-size: 32px;
        font-weight: bolder;
        color: #000000;
        text-align: center;
    }
}
.el-tab-pane{
   background-color: #ececec;
}

.card_group{
  width: 50%;
  margin-right: 6px;
  background: #fff;
  border-radius: 4px;
}

@media screen and (max-width: 768px) {}

#daiduMap,
#leafletmap {
    width: 400px;
    height: 400px;
}
</style>