import wujieVue from "wujie-vue2";
import router from "./router";
const lifecycles = {
  beforeLoad: (appWindow) => console.log(`${appWindow.__WUJIE.id} beforeLoad 生命周期`),
  beforeMount: (appWindow) => console.log(`${appWindow.__WUJIE.id} beforeMount 生命周期`),
  afterMount: (appWindow) => {
    console.log(`${appWindow.__WUJIE.id} afterMount 生命周期`)
    if(appWindow.__WUJIE.id == 'child_project02'){
      wujieVue.bus.$emit("sub-route-change3", window.location.hash.substr(1));
    }
    if(appWindow.__WUJIE.id == 'mmscmWeb'){
      wujieVue.bus.$emit("sub-route-change2", window.location.hash.substr(1));
    }
    if(appWindow.__WUJIE.id == 'mmscmBms'){
      wujieVue.bus.$emit("sub-route-bms-change", window.location.hash.substr(1));
    }
  },
  beforeUnmount: (appWindow) => console.log(`${appWindow.__WUJIE.id} beforeUnmount 生命周期`),
  afterUnmount: (appWindow) => console.log(`${appWindow.__WUJIE.id} afterUnmount 生命周期`),
  activated: (appWindow) =>{
    if(appWindow.__WUJIE.id == 'child_project02'){
      wujieVue.bus.$emit("sub-route-change3", window.location.hash.substr(1));
    }
    if(appWindow.__WUJIE.id == 'mmscmWeb'){
      wujieVue.bus.$emit("sub-route-change2", window.location.hash.substr(1));
    }

    if(appWindow.__WUJIE.id == 'mmscmBms'){
      wujieVue.bus.$emit("sub-route-bms-change", window.location.hash.substr(1));
    }
    console.log(`${appWindow.__WUJIE.id} activated 生命周期`)
  },
  deactivated: (appWindow) => console.log(`${appWindow.__WUJIE.id} deactivated 生命周期`),
  loadError: (url, e) => console.log(`${url} 加载失败`, e),
};

export default lifecycles;
