import website from '@/config/website'
import { validatenull } from '@/utils/validate';

// const keyName = '';
const keyName = website.key + '-';
/**
 * 存储sessionStorage
 */
 export const setStore = (params = {}) => {
  let { name, content, type, } = params;
  name = keyName + name
  let obj = {
      dataType: typeof (content),
      content: content,
      type: type,
      datetime: new Date().getTime()
  }
  if (true) window.sessionStorage.setItem(name, JSON.stringify(obj));
  else { }
}

/**
 * 获取sessionStorage
 */

 export const getStore = (params = {}) => {
  let { name, debug } = params;
  name = keyName + name
  let obj = {}, content;
  obj = window.sessionStorage.getItem(name);
  if (validatenull(obj)) obj = window.sessionStorage.getItem(name);
  if (validatenull(obj)) return;
  try {
      obj = JSON.parse(obj);
  } catch{
      return obj;
  }
  if (debug) {
      return obj;
  }
  if (obj.dataType == 'string') {
      content = obj.content;
  } else if (obj.dataType == 'number') {
      content = Number(obj.content);
  } else if (obj.dataType == 'boolean') {
      content = eval(obj.content);
  } else if (obj.dataType == 'object') {
      content = obj.content;
  }
  return content;
}

/**
 * 删除sessionStorage
 */
 export const removeStore = (params = {}) => {
  let { name, type } = params;
  name = keyName + name
  if (true) {
      window.sessionStorage.removeItem(name);
  } else {
  }
}

/**
 * 获取全部sessionStorage
 */
 export const getAllStore = (params = {}) => {
  let list = [];
  let { type } = params;
  if (true) {
      for (let i = 0; i <= window.sessionStorage.length; i++) {
          list.push({
              name: window.sessionStorage.key(i),
              content: getStore({
                  name: window.sessionStorage.key(i),
                  type: 'session'
              })
          })
      }
  } else {
  }
  return list;
}

/**
* 清空全部sessionStorage
*/
export const clearStore = (params = {}) => {
  let { type } = params;
  if (true) {
      window.sessionStorage.clear();
  } else {
  }

}