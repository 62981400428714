<template>
  <!--单例模式，name相同则复用一个无界实例，改变url则子应用重新渲染实例到对应路由 -->
  <WujieVue width="100%" height="100%" name="demo-vue2" :url="vue2Url"></WujieVue>
</template>

<script>
import hostMap from "../hostMap";

export default {
  computed: {
    vue2Url() {
      console.log(this.$route.params.path,555555)
      return hostMap("//127.0.0.1:7200/") + `#/${this.$route.params.path}`;
    },
  },
  methods: {
    // jump(name) {
    //   this.$router.push({ name });
    // },
  },
};
</script>

<style lang="scss" scoped></style>
