<template>
  <el-dialog append-to-body :title="form.messageTitle" :close-on-click-modal="false" :close-on-press-escape="false" draggable
    :visible.sync="dialogVisible" width="60%" destroy-on-close center>
    <el-row>
      <div style="padding: 40px" class="htmlDiv" v-html="form.messageContent" />
    </el-row>
     <!-- <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span> -->
  </el-dialog>
</template>

<script>
import { noticeMessageDetail } from '@/api/menu.js';
export default {
  name: "informView",
  data() {
    return {
      dialogVisible: false,
      form: {
        versions: '',
        id: '',
        messageTitle: "",
        messageContent: ""
      } 
    };
  },
  methods: {
    getInfo(id) {
      this.form.id = id
      noticeMessageDetail(id).then(res => {
          if (res.code === 200) {
            this.form = Object.assign(this.form, res.data)
          }
      })
    }
  }
};
</script>

<style>
.htmlDiv img {
    max-width: 100%;
}
</style>