import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import { isRelogin } from '@/utils/request'
import {validatenull} from '@/utils/validate'

NProgress.configure({ showSpinner: false })

const whiteList = ['/login', '/register']

router.beforeEach((to, from, next) => {
    NProgress.start()
    // console.log(getToken(),"阿琴阿琴阿钦")
    if (getToken() && getToken() != 'null') {
      if (to.path === '/login') { //如果登录成功访问登录页跳转到主页
        next({path: '/'})
      } else {
        //如果用户信息为空则获取用户信息，获取用户信息失败，跳转到登录页
        if (store.getters.token.length === 0) {
          store.dispatch('FedLogOut').then(() => {
            next({path: '/login'})
          })
        } else {
            if (!to.path || to.path == '' || to.path == null) {
                to.path = "#";
            }
            if (to.path === '/login') {
                next({ path: '/' })
                NProgress.done()
            } else {
                // store.dispatch('GenerateRoutes').then(accessRoutes => {
                //     console.log("GenerateRoutes",accessRoutes)
                //     // 根据roles权限生成可访问的路由表
                //     router.addRoutes(accessRoutes) // 动态添加可访问路由表
                //     next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
                // })
                // if (store.getters.permissions.length === 0) {
                //   store.dispatch('GenerateRoutes').then(accessRoutes => {
                //       // 根据roles权限生成可访问的路由表
                //       router.addRoute(accessRoutes) // 动态添加可访问路由表
                //       next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
                //   })
                // } else {
                //     next()
                // }
                // next()
            }
          // const value = to.query.src || to.fullPath;
          // const label = to.query.name || to.name;
          // const meta = to.meta || router.$mmscmRouter.meta || {};
          // if (to.path.indexOf('/work/processDetail') >= 0) {
          //   let tagList = store.getters.tagList;
          //   let hasFlow = false
          //   let flowIndex = 0
          //   for(let i = 0; i < tagList.length; i++) {
          //     if (tagList[i].value.indexOf('/work/processDetail') >= 0) {
          //       hasFlow = true
          //       flowIndex = i
          //       break;
          //     }
          //   }
          //   if (hasFlow) {
          //     store.commit("DEL_TAG", tagList[flowIndex]);
          //   }
          // }
          // if (to.query.target) {
          //   window.open(value)
          // } 
          // else if (meta.isTab !== false && !validatenull(value) && !validatenull(label)) {
          //   store.commit('ADD_TAG', {
          //     label: label,
          //     value: value,
          //     params: to.params,
          //     query: to.query,
          //     group: router.$mmscmRouter.group || []
          //   });
          // }
          next()
        }
      }
        // to.meta.title && store.dispatch('settings/setTitle', to.meta.title)
        // /* has token*/
        // if (!to.path || to.path == '' || to.path == null) {
        //     to.path = "#";
        // }
        // if (to.path === '/login') {
        //     next({ path: '/' })
        //     NProgress.done()
        // } else {
        //     if (store.getters.roles.length === 0) {
        //         isRelogin.show = true
        //         // 判断当前用户是否已拉取完user_info信息
        //         store.dispatch('GetInfo').then(() => {
        //             isRelogin.show = false
        //             store.dispatch('GenerateRoutes').then(accessRoutes => {
        //                 // 根据roles权限生成可访问的路由表
        //                 router.addRoute(accessRoutes) // 动态添加可访问路由表
        //                 next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
        //             })
        //         }).catch(err => {
        //             store.dispatch('LogOut').then(() => {
        //                 Message.error(err)
        //                 next({ path: '/' })
        //             })
        //         })
        //     } else {
        //         next()
        //     }
        // }
    } else {
        // 没有token
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next()
        } else {
              // next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
              next(`/login`) // 否则全部重定向到登录页
              NProgress.done()
        }
    }
})

router.afterEach(() => {
    NProgress.done()
})
