import Cookies from 'js-cookie'
import website from '@/config/website'
const TokenKey = 'saber-access-token'
const RefreshTokenKey = 'saber-refresh-token'
// const TokenKey = 'Admin-Token'
const keyName = website.key + '-';

export function getToken() {
  return sessionStorage.getItem(TokenKey)
  // return Cookies.get(TokenKey)
}

export function setToken(token) {
  return sessionStorage.setItem(TokenKey, token)
  // return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return sessionStorage.removeItem(TokenKey)
  // return Cookies.remove(TokenKey)
}

export function getRefreshToken() {
  return sessionStorage.getItem(RefreshTokenKey)
  // return Cookies.get(RefreshTokenKey)
}

export function setRefreshToken(token) {
  return sessionStorage.setItem(RefreshTokenKey, token)
  // return Cookies.set(RefreshTokenKey, token)
}

export function removeRefreshToken() {
  return sessionStorage.removeItem(RefreshTokenKey)
  // return Cookies.remove(RefreshTokenKey)
}